<template>
  <div class="div_maj">
    <div class="titre">{{ page_succes().title }}</div>
    <div class="texte">{{ page_succes().desc }}</div>
  </div>
</template>

<script>
import page_suc from "@/json/page_succes.json";

export default{
  data() {
    return {
      page_suc: page_suc,
    }
  },
  methods:{
    page_succes(){
        return page_suc[this.$store.state.codeLangue]
    },
  },
  mounted(){
    console.log(this.$route)
    setTimeout(()=>{
        this.$router.push('./')
    }, 5000);
  }
}
</script>

<style scoped>

.div_maj{
  padding-top: 200px;
  height: -webkit-fill-available;
}

.titre{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
}

.texte{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

</style>